body {
  max-width: 100vw;
  overflow-x: hidden;
}

.icon-sidebar path {
  stroke: #004f9f;
}

.home-icon path {
  stroke: #fff;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    height: 0;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #004f9f;
  border-radius: 08px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00509f9e;
}

.main-section {
  position: absolute;
  top: 0;
  left: 18rem;
  width: calc(100vw - 18rem);
  overflow-x: hidden;
  max-width: 100vw;
  background-color: #fbfcfe;
  min-height: 100vh;
  place-items: center;
  padding: 30px 60px 50px 60px;
}

.main-section2 {
  position: absolute;
  top: 0;
  left: 16rem;
  width: calc(100vw - 16rem);
  overflow-x: hidden;
  max-width: 100vw;
  background-color: #fbfcfe;
  min-height: 100vh;
  gap: 20px;
  padding: 60px 0;
}

@media (max-width: 1024px) {
  .main-section2 {
    left: 0;
    width: 100vw;
    padding: 0px 10px 50px 10px;
    transform: translateY(103px);
    min-height: 90vh;
  }

  .main-section {
    left: 0;
    width: 100vw;
    padding: 0px 20px 50px 20px;
    transform: translateY(103px);
  }
}

.table-overflow>div:first-of-type {
  overflow: visible !important
}

.rdt_TableCell {
  color: #75787b;
  font-size: 15px;
}

.rdt_TableRow:last-child:has(.dropdown:hover) {
  margin-bottom: 40px;
}

.alert-condition-show{
  display: none;
}

.row-with-warning .alert-condition-show{
  display: block;
}

.rdt_Table {
  background-color: #fbfcfe !important;
  overflow: visible !important;
}

.rdt_TableRow {
  height: 100px !important;
}

.eUeqdG {
  color: #004f9f !important;
  font-size: 17px;
  font-weight: 600;
}

.gJhVev span {
  opacity: 1 !important;
  transform: rotate(180deg);
  margin-left: 5px;
  color: #004f9f !important;
}

.rdt_TableRow {
  height: 70px;
  background-color: #fbfcfe !important;
}

.rdt_TableHeadRow,
.rdt_Pagination {
  background-color: #fbfcfe !important;
}

.bg-white-table .rdt_TableRow,
.bg-white-table .rdt_TableHeadRow,
.bg-white-table .rdt_Pagination {
  background: #fff !important;
}

.bnAwAJ {
  margin-right: 30px;
}

@media (min-width: 1024px) {
  .desktop-none {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .mobile-tablet-none {
    display: none !important;
  }

  .rdt_TableCell {
    font-size: 12px;
  }

  .eUeqdG {
    font-size: 14px;
  }

  .eObVHB {
    min-width: 90px !important;
    max-width: 90px !important;
  }

  .kIbpEx {
    min-width: 180px !important;
    max-width: 180px !important;
  }

  .fXHZsh {
    min-width: 140px !important;
    max-width: 140px !important;
    margin-right: 30px !important;
  }

  .dYitNJ {
    min-width: 125px !important;
    max-width: 125px !important;
  }

  .eiRSNZ {
    min-width: 150px !important;
    max-width: 150px !important;
  }

  .bnAwAJ {
    margin-right: 40px !important;
  }
}

.dropdown {
  position: absolute;
  display: inline-block;
  background-color: transparent;
  overflow: visible !important;
}

.dropdown-mobile {
  position: absolute;
  display: inline-block;
  background-color: transparent;
  overflow: visible !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: -2px;
  width: 165px;
  padding: 6px 16px;
  z-index: 100000;
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: #eff7ff;
  color: #004f9f;
  border-top: 1px solid #004f9f;
  border-left: 2px solid #004f9f;
  border-right: 2px solid #004f9f;
  border-bottom: 2px solid #004f9f;
}

.dropdown-content-mobile {
  display: grid;
  gap: 15px;
  position: absolute;
  top: 100%;
  left: -2px;
  width: calc(100% + 4px);
  padding: 6px 16px;
  z-index: 100000;
  background-color: #eff7ff;
  color: #004f9f;
  border-top: 1px solid #004f9f;
  border-left: 2px solid #004f9f;
  border-right: 2px solid #004f9f;
  border-bottom: 2px solid #004f9f;
}

.table-container div:first-child {
  overflow-y: visible;
}

.dropdown:hover .dropdown-content {
  display: grid;
  gap: 15px;
}

.history-table-header {
  background-color: #f3f3f3;
}

.history-table-content {
  background-color: #ffffff;
}

.history-table {
  border: 1px solid #e1e1e1;
}

.history-table-header div:not(:last-child),
.history-table-content div:not(:last-child) {
  border-right: 1px solid #e1e1e1;
}

.history-table-header div:not(:first-child),
.history-table-content div:not(:first-child) {
  min-height: 57px;
  display: grid;
  place-items: center start;
}

.history-table-content {
  border-top: 1px solid #e1e1e1;
}

.custom-select__placeholder {
  position: absolute;
  width: 300px;
}

@media (max-width: 1024px) {
  .dropdown:hover .dropdown-content {
    gap: 10px;
  }

  .dropdown-content-mobile {
    gap: 10px;
  }

  .dropdown-content, .dropdown-content-mobile {
    min-width: 140px;
  }
}

.dropdown:hover {
  border: 2px solid #004f9f;
  z-index: 99999999;
  border-radius: 0.5rem 0.5rem 0 0;
}

.dropdown2 {
  position: relative;
  display: inline-block;
  background-color: transparent;
}

.dropdown2-content {
  display: none;
  position: relative;
  min-width: 132px;
  padding: 6px 16px;
  z-index: 100000;
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: #eff7ff;
  color: #004f9f;
  border-top: 1px solid #004f9f;
  font-size: 12px;
}

.dropdown2:hover .dropdown2-content {
  display: grid;
  gap: 5px;
}

.dropdown2:hover {
  z-index: 99999999;
  border-radius: 0.5rem;
  border: 1px solid #004f9f;
  position: absolute;
  transform: translateY(27px);
}

.checkbox-container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  top: -10px;
  left: -20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #004f9f;
}

@media (max-width: 1024px) {
  .checkbox-checkmark {
    width: 15px;
    height: 15px;
  }

  .checkbox-container {
    top: -5px;
    left: -15px;
  }
}

.checkbox-container input:checked~.checkbox-checkmark {
  background-color: #fff;
}

.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked~.checkbox-checkmark:after {
  display: block;
}

.checkbox-container .checkbox-checkmark:after {
  left: 5.5px;
  top: 1px;
  width: 8px;
  height: 14px;
  border: solid #004f9f;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

@media (max-width: 1024px) {
  .dropdown2:hover .dropdown2-content {
    gap: 10px;
  }

  .dropdown2-content {
    min-width: 132px;
  }

  .dropdown2:hover {
    transform: translateY(30px);
  }
}

.input-with-image input {
  background: url(https://cdn-icons-png.flaticon.com/512/2948/2948088.png);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 17px;
  width: 100%;
}

.transparent-bg__control {
  background-color: transparent !important;
}

.PhoneInputInput {
  background-color: transparent;
}

.PhoneInputInput:focus-visible {
  outline: 2px solid #004f9f;
  padding: 0 7px;
  border-radius: 4px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  text-align: center;
  width: "100%";
  min-height: 150px;
}

.small-date-input input {
  width: 100%;
}

@media (max-width: 1024px) {
  .dropzone {
    width: 100%;
    padding: 50px 20px;
  }
}

.searchlist {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 500px;
  margin-top: 6px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  z-index: 9999;
  color: #a3a3a3;
  left: 50%;
  transform: translateX(-50%);
}

.searchlist li {
  padding: 10px;
  background: white;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 0.5px;
  font-size: 18px;
}

.searchlist li:hover {
  background-color: #004f9f;
  color: azure;
  border-color: #004f9f;
}

.searchlist li:last-child {
  border-bottom: 0;
}

.searchinput:focus-visible,
.input-with-image input:focus-visible {
  outline: 2px solid #004f9f;
}

.line-after::after {
  background-color: #e9e9e9;
  content: "";
  height: 59px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 30px;
  width: 2px;
}

@media (max-width: 768px) {
  .dnyRvT {
    min-width: 390px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.no-focus-visible:focus-visible {
  outline: 0;
}

.no-focus-visible input:focus-visible {
  outline: 0;
}

.gJhVev span.__rdt_custom_sort_icon__.asc svg {
  opacity: 1;
}

.fileicon svg {
  width: 40px;
}

.nodatacomponent {
  padding: 50px !important;
  border: 1px solid #004f9f !important;
  border-radius: 0 !important;
  width: 100%;
  text-align: center;
}

.companyfield .css-qc6sy-singleValue {
  background: #fff;
  z-index: 1000;
  width: 100%;
}